import instance from './base.service';

class ProducerService {
  async index() {
    return await instance.get('api/producers.json')
  }

  async find(slug) {
    return await instance.get(`api/producers/${slug}.json`)
  }

  async adminIndex() {
    return await instance.get('api/producers/admin_index.json')
  }

  async create(data) {
    return await instance.post('api/producers.json', { producer: data })
  }
}

export default new ProducerService();